import {GoogleMapsGigro} from "./map";

/********** submenu */
$('.dropdown-menu a.dropdown-toggle').on('click', function(e) {
    if (!$(this).next().hasClass('show')) {
        $(this).parents('.dropdown-menu').first().find('.show').removeClass("show");
    }
    var $subMenu = $(this).next(".dropdown-menu");
    $subMenu.toggleClass('show');

    $(this).parents('li.nav-item.dropdown.show').on('hidden.bs.dropdown', function(e) {
        $('.dropdown-submenu .show').removeClass("show");
    });

    return false;
});

/********** Âncora animate */
$(function() {
    $('.moduloAncora').bind('click',function(event){
        var $anchor = $(this);

        $('html, body').stop().animate({scrollTop: $($anchor.attr('href')).offset().top}, 1000,'easeInOutExpo');
        // Outras Animações
        // linear, swing, jswing, easeInQuad, easeInCubic, easeInQuart, easeInQuint, easeInSine, easeInExpo, easeInCirc, easeInElastic, easeInBack, easeInBounce, easeOutQuad, easeOutCubic, easeOutQuart, easeOutQuint, easeOutSine, easeOutExpo, easeOutCirc, easeOutElastic, easeOutBack, easeOutBounce, easeInOutQuad, easeInOutCubic, easeInOutQuart, easeInOutQuint, easeInOutSine, easeInOutExpo, easeInOutCirc, easeInOutElastic, easeInOutBack, easeInOutBounce

    });
});

/********** paginação */
if ($('.pagination').length > 0) {
    $(".pagination").twbsPagination({
        totalPages: pages,
        visiblePages: 7,
        href: pagesLink,
        first: '<<',
        prev: '<',
        next: '>',
        last: '>>',
        loop: true
    });
}

/************ voltar para o topo*/
$(window).scroll(function() {
    if ($(this).scrollTop() > 400) {
        $('#voltarTopo').removeClass("d-none bounceOutDown").addClass("bounceInUp animated");
    } else {
        $('#voltarTopo').addClass("bounceOutDown animated");
    }
});

$('#voltarTopo').click(function() {
    $('html,body').animate({
        scrollTop: 0
    },1000, 'easeInOutExpo');
});

/***************WOW animação
new window.wow().init();*/

/***************parallax*/
$('.parallax').each(function(){
    var $obj = $(this);

    $(window).scroll(function() {
        var yPos = -($(window).scrollTop() / 10);

        var bgpos = '50% '+ yPos + 'px';

        $obj.css('background-position', bgpos );

    });
});

/* Mapa home */

$('.home-map').each( function (index) {
    const mapId = $(this)[0].id;
    const cord = $(this).data('cord').split(" ");
    new GoogleMapsGigro(mapId).init(cord[0], cord[1]);
});

/********* mascaras para formulários do MaskedInput*/
$("form .telefone").each(function(index, element) {
    $("form .telefone").mask("(99)99999999?9");
});

function CarregarMunicipios(campoUfs, campoMunicipios, selecionada) {
    campoUfs = jQuery(campoUfs);
    campoMunicipios = jQuery("#" + campoMunicipios);
    campoMunicipios.html("<option value=''>Carregando...</option>");
    jQuery.post("/classes.php", {
        objeto: 'Municipio',
        metodo: 'Listar',
        uf: campoUfs.val(),
        limit: 0
    }, function(retorno) {
        retorno = jQuery.parseJSON(retorno);
        campoMunicipios.html("<option value=''>Selecione o município</option>");
        for (i in retorno['linhas']) {
            campoMunicipios.append("<option value='" + retorno['linhas'][i]['nome'] + "'>" + retorno['linhas'][i]['nome'] + "</option>");
        }
        if (selecionada) {
            campoMunicipios.val(selecionada);
        }
    });
}
/*function ValidarEmail(email) {
    var filter = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
    return filter.test(email);
}

function ValidarFormulario(formulario) {
    var ok = true;
    var senha = "";
    $(formulario).find(".validar").each(function() {
        if (($(this).hasClass('senha')) && (senha == "")) {
            senha = $(this).val();
        }
        if (($(this).val() == "") || (($(this).hasClass('email')) && (!ValidarEmail($(this).val()))) || (($(this).hasClass('senha')) && (senha != $(this).val()))) {
            $(this).addClass('invalido');
            ok = false;
        } else {
            $(this).removeClass('invalido');
        }
    });
    if (!ok) {
        alert("Verifique os campos com borda vermelha.");
        return false;
    }
    return true;
}



Callback = function() {
    return false
};*/

function Recarregar() {
    location.reload();
}

function Redirecionar(link) {
    window.document.location = link;
}

function Deslogar() {
    $.post("/classes.php", {
        objeto: 'Usuario',
        metodo: 'Deslogar'
    }, function(retorno) {
        retorno = $.parseJSON(retorno);
        if (retorno.success) {
            Redirecionar('/home');
        } else {
            alert(retorno.msg);
        }
    });
}

function LimparSenha(campo1, campo2) {
    var texto = '******';
    campo1 = $('#' + campo1);
    campo2 = $('#' + campo2);
    if (campo1.val() == texto) {
        campo1.val('');
    }
    if (campo2.val() == texto) {
        campo2.val('');
    }
}

function PreencherSenha(campo1, campo2) {
    var texto = '******';
    campo1 = $('#' + campo1);
    campo2 = $('#' + campo2);
    if (campo1.val() == '') {
        campo1.val(texto);
    }
    if (campo2.val() == '') {
        campo2.val(texto);
    }
}

function TrocarSenha(formulario) {
    $('#formLogin button#btnEntrar').attr('disabled', 'disabled');
    $('#formLogin button#btnEntrar').html('<img src="/img/loader.gif" alt="" />');
    var dados = $(formulario).serialize() + "&objeto=Usuario&metodo=TrocarSenha";
    $.post("/classes.php", dados, function(retorno) {
        retorno = $.parseJSON(retorno);
        $('#formLogin button#btnEntrar').removeAttr('disabled');
        $('#formLogin button#btnEntrar').html('Entrar');
        alert(retorno.msg);
        Recarregar();
    });
}
