window.$ = window.jQuery = require('jquery');
require('popper.js/dist/popper');
require('bootstrap');
// require('../scss/bootstrap.scss');
require('./plugins/jquery.easing.1.3');
require('./plugins/jquery.maskedinput-1.4.1');
require('./plugins/jquery.validate');
require('./plugins/jquery.form.min');
require('./plugins/jquery.twbsPagination');
require('./plugins/OwlCarousel2-2.3.4/owl.carousel.min');
// require('@fancyapps/fancybox/dist/jquery.fancybox.css');
require('@fancyapps/fancybox/dist/jquery.fancybox');
// window.wow = require('./plugins/wow.min');
// require('./plugins/smoothScroll.min');
//window.scrollreveal = require('./plugins/scrollreveal.min');

require('./index.js');
